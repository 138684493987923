import { CourierSurvey, CourierSurveyResponse } from 'services';

const API_BASE = process.env.REACT_APP_API_BASE;

async function request(
  userType: 'sender' | 'receiver',
  surveyId: string,
  score: string,
): Promise<CourierSurveyResponse> {
  if (!surveyId) throw new Error();

  const key = userType === 'sender' ? 'overall_experience_score' : 'delivery_time_score';
  const res = await fetch(`${API_BASE}/v1/${userType}_surveys/${surveyId}`, {
    method: 'PATCH',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: score && !isNaN(+score) ? JSON.stringify({ [key]: (+score * 2.5).toString() }) : '{}',
  });
  return res.ok ? res.json() : null;
}

async function submit(userType: 'sender' | 'receiver', survey: CourierSurvey): Promise<CourierSurveyResponse> {
  const senderOnly =
    userType === 'receiver'
      ? {}
      : {
          company: { send_receiver_survey: 'sendReceiverSurvey' },
        };
  const payload = {
    [`${userType}_survey`]: {
      ...survey,
      is_complete: true,
    },
    ...senderOnly,
  };

  const res = await fetch(`${API_BASE}/v1/${userType}_surveys/${survey.id}`, {
    method: 'PATCH',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(payload),
  });
  return res.ok ? res.json() : null;
}

const SurveyService = { request, submit };
export default SurveyService;
