import { makeStyles, Theme } from '@material-ui/core/styles';
import COLORS from 'config/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px 35px 15px',
    margin: '15px 0',
    minWidth: '100%',

    '& .MuiGrid-item': {
      width: '50%',
      height: 45,

      '& .MuiButton-root': {
        height: 35,
        width: 175,
      },

      '& .MuiButton-text': {
        padding: '8px 25px',
      },
    },

    '& .MuiGrid-item:nth-child(even)': {
      textAlign: 'right',
    },

    '& .MuiGrid-item:nth-child(n+3)': {
      marginTop: 10,
    },

    [theme.breakpoints.down('xs')]: {
      padding: 20,

      '& .MuiGrid-item': {
        width: '100%',
        height: 'auto',
      },

      '& .MuiGrid-item:nth-child(2)': {
        textAlign: 'left',
        marginTop: 10,
      },

      '& .MuiGrid-item:nth-child(n+3)': {
        textAlign: 'center',
      },
    },
  },
  link: {
    color: COLORS.blueNormal,
    textDecoration: 'underline',

    '&:hover': {
      color: COLORS.blueDark,
      cursor: 'pointer',
    },
  },
  contactCourier: {
    marginTop: 5,
    boxShadow: '0px 2px 4px rgba(40, 78, 102, 0.2) !important',

    [theme.breakpoints.down('xs')]: {
      width: '95% !important',
    },
  },
  courierLogo: {
    width: 40,
    height: 30,
    borderRadius: 4,
    marginRight: 8,
  },
  courierName: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      wordBreak: 'normal',
      width: 168,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: 210,
      },
    },
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
}));
