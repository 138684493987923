import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Shipment } from 'services/index.d';

export type AdvertisementProps = React.HTMLAttributes<string> & {
  shipment: Shipment;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 15,
  },
  companyName: {
    fontSize: 30,
    fontWeight: 700,
    color: theme?.palette?.ink?.dark,
  },
}));

export default function Advertisement(props: AdvertisementProps) {
  const classes = useStyles();
  const { company_logo_url, company_name, show_header } = props.shipment;

  return (
    <>
      {show_header && (
        <Grid container justifyContent="center" className={classes.root}>
          {company_logo_url ? (
            <Grid item>
              <img src={company_logo_url} alt={company_name} />
            </Grid>
          ) : (
            <p className={classes.companyName}>{company_name}</p>
          )}
        </Grid>
      )}
    </>
  );
}
