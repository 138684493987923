const COLORS = {
  black: 'hsl(0, 0%, 0%)', // #000000
  white: 'hsl(0, 0%, 100%)', // #FFFFFF;

  success: 'rgba(80, 212, 164, 1)', // #50D4A4
  failure: 'rgba(250, 100, 0, 1)', // #FA6400
  waiting: 'rgba(247, 181, 0, 1)', // #F7B500
  closed: 'rgba(69, 79, 91, 1)', // #454F5B

  tealLight: 'rgba(237, 251, 250, 1)', // #EDFBFA
  tealNormal: 'rgba(76, 202, 206, 1)', // #4CCACE
  tealDark: 'rgba(43, 144, 147, 1)', // #2B9093
  tealDarkest: 'rgba(17, 90, 101, 1)', // #115A65

  blueLight: 'rgba(235, 244, 250, 1)', // #EBF4FA
  blueNormal: 'rgba(31, 154, 239, 1)', // #1F9AEF
  blueDark: 'rgba(23, 125, 196, 1)', // #177DC4
  blueDarkest: 'rgba(17, 90, 141, 1)', // #115A8D

  greenLight: 'rgba(220, 246, 237, 1)', // #DCF6ED
  greenNormal: 'rgba(80, 212, 164, 1)', // #50D4A4
  greenDark: 'rgba(40, 161, 123, 1)', // #28A17B
  greenDarkest: 'rgba(17, 110, 96, 1)', // #116E60

  yellowLight: 'rgba(255, 244, 215, 1)', // #FFF4D7
  yellowNormal: 'rgba(255, 201, 56, 1)', // #FFC938
  yellowDark: 'rgba(247, 181, 0, 1)', // #F7B500
  yellowDarkest: 'rgba(218, 157, 33, 1)', // #DA9D21

  redLight: 'rgba(254, 227, 226, 1)', // #FEE3E2
  redNormal: 'rgba(252, 113, 109, 1)', // #FC716D
  redDark: 'rgba(192, 15, 25, 1)', // #C00F19
  redDarkest: 'rgba(146, 12, 19, 1)', // #920C13

  inkLight: 'rgba(145, 158, 171, 1)', // #919FAB
  inkFaded: 'rgba(99, 115, 129, 1)', //#637381,
  inkNormal: 'rgba(69, 79, 91, 1)', // #454F5B
  inkDark: 'rgba(33, 43, 54, 1)', // #212B36
  inkDarkest: 'rgba(0, 18, 34, 1)', // #001222

  skyLight: 'rgba(249, 250, 251, 1)', // #F9FAFB
  skyNormal: 'rgba(244, 246, 248, 1)', // #F4F6F8
  skyDark: 'rgba(223, 227, 232, 1)', // #DFE3E8
  skyDarkest: 'rgba(196, 205, 213, 1)', // #C4CDD5

  greenGradient: 'transparent linear-gradient(180deg, #50D4A4 0%, #28A17B 100%) 0% 0% no-repeat padding-box',

  boxShadow: 'rgba(0, 0, 0, 0.4)', // #00000040
};

export default COLORS;
