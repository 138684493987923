import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import 'config/sentry';
import { MixpanelContext, mixpanelLibrary } from 'config/analytics';
import { theme } from 'config/theme';
import { getLocale, messageDictionary, AvailableLocale } from 'config/locale';
import './fonts/index.css';

import HomeScreen from './screens/HomeScreen';
import TrackingScreen from './screens/TrackingScreen';
import CourierSurveyScreen from './screens/CourierSurveyScreen';
import UnsubscribeScreen from './screens/UnsubscribeScreen';
import RedirectScreen from './screens/RedirectScreen';

import RecaptchaService from 'services/recaptcha';

function App() {
  const locale: AvailableLocale = getLocale();
  const [isInitializingToken, setIsInitializingToken] = useState(true);

  useEffect(() => {
    RecaptchaService.init(true).then(() => setIsInitializingToken(false));
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Track My Shipment</title>
        <meta name="description" content="Track shipments from almost any express courier from store to door" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.trackmyshipment.co/" />
        <meta property="og:title" content="Track My Shipment" />
        <meta property="og:description" content="Track shipments from almost any express courier from store to door" />
        <meta property="og:image" content="https://www.trackmyshipment.co/images/1-ready-to-ship.svg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.trackmyshipment.co/" />
        <meta property="twitter:title" content="Track My Shipment" />
        <meta
          property="twitter:description"
          content="Track shipments from almost any express courier from store to door"
        />
        <meta property="twitter:image" content="https://www.trackmyshipment.co/images/1-ready-to-ship.svg" />
      </Helmet>

      {!isInitializingToken && (
        <MixpanelContext.Provider value={mixpanelLibrary}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <IntlProvider locale={locale} messages={messageDictionary[locale] as Record<string, string>}>
              <BrowserRouter>
                <Switch>
                  <Route path="/shipment-tracking/:keyword/:companyId?">
                    <TrackingScreen />
                  </Route>
                  <Route path="/track/:keyword">
                    <TrackingScreen />
                  </Route>
                  <Route path="/surveys/:userType/:surveyId">
                    <CourierSurveyScreen />
                  </Route>
                  <Route path="/unsubscribe">
                    <UnsubscribeScreen />
                  </Route>
                  <Route path="/shipment-redirect/:action">
                    <RedirectScreen />
                  </Route>
                  <Route path="/">
                    <HomeScreen />
                  </Route>
                </Switch>
              </BrowserRouter>
            </IntlProvider>
          </ThemeProvider>
        </MixpanelContext.Provider>
      )}
    </HelmetProvider>
  );
}

export default App;
