import { Typography, Container, ButtonGroup, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Shipment } from 'services/index.d';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 20,
  },
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'center',

    '& .MuiButtonBase-root': {
      border: 'none',
    },

    '& .MuiSvgIcon-root': {
      fontSize: 21,
      color: theme?.palette?.ink?.light,
    },

    '& .MuiButton-root:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  mb20: {
    marginBottom: 20,
  },
}));

interface FooterProps {
  shipment: Shipment;
}

export default function Footer(props: FooterProps) {
  const classes = useStyles();
  const { facebook_url, instagram_url, twitter_url, youtube_url } = props.shipment.company_social_media;
  const { show_contact, company_footnote } = props.shipment;

  const RE_PROTOCOL = /^http/;
  const protocolChecker = (url: string) => (RE_PROTOCOL.test(url) ? url : `//${url}`);

  return (
    <Container className={classes.root}>
      {company_footnote && (
        <Typography component="p" variant="body2" align="center">
          {company_footnote}
        </Typography>
      )}
      {show_contact && (
        <div className={`${classes.buttonGroupContainer} ${classes.mb20}`}>
          <ButtonGroup size="small" aria-label="small text button group" disableElevation={true} disableRipple={true}>
            {facebook_url && (
              <Button component="a" href={protocolChecker(facebook_url)} target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </Button>
            )}
            {twitter_url && (
              <Button component="a" href={protocolChecker(twitter_url)} target="_blank" rel="noopener noreferrer">
                <TwitterIcon />
              </Button>
            )}
            {instagram_url && (
              <Button component="a" href={protocolChecker(instagram_url)} target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </Button>
            )}
            {youtube_url && (
              <Button component="a" href={protocolChecker(youtube_url)} target="_blank" rel="noopener noreferrer">
                <YouTubeIcon />
              </Button>
            )}
          </ButtonGroup>
        </div>
      )}
    </Container>
  );
}
