import { useState, Fragment } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { Shipment, Checkpoint } from 'services/index.d';

type HistoryProps = React.HTMLAttributes<string> & {
  shipment: Shipment;
};

export default function History(props: HistoryProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const [expanded, setExpanded] = useState(false);
  const shipment = props.shipment;

  const showExpandButton = shipment.checkpoints.length > 2;

  function checkpointLocationDisplay(checkpoint: Checkpoint) {
    return [checkpoint.handler, checkpoint.location, checkpoint.city, checkpoint.country_name]
      .filter((value) => value)
      .join(', ');
  }

  function checkpointLocalTimeDisplay(checkpoint_time: string) {
    const utcDate = new Date(checkpoint_time);
    const date = utcDate.toLocaleString('en-US', {
      dateStyle: 'medium',
      timeZone: 'UTC',
    });
    const time = utcDate.toLocaleString('en-US', {
      hour: '2-digit',
      minute: 'numeric',
      timeZone: 'UTC',
    });

    return `${date} ${time}`;
  }

  return (
    <Container
      className={`${classes.root} ${expanded && classes.expanded} ${!showExpandButton && classes.reducePadding}`}
    >
      {shipment.checkpoints.map((checkpoint) => (
        <Grid
          key={checkpoint.order_number}
          container
          direction={isNarrow ? 'column' : 'row'}
          wrap="nowrap"
          spacing={isNarrow ? 0 : 4}
          className={classes.checkpoint}
        >
          <Grid item className={classes.checkpointTime}>
            <Typography component="span" variant="subtitle2">
              {checkpoint.checkpoint_time ? (
                checkpointLocalTimeDisplay(checkpoint.checkpoint_time)
              ) : (
                <FormattedMessage id="global.not-available" defaultMessage="Not Available"></FormattedMessage>
              )}
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography component="p" variant="subtitle1">
                {checkpoint.message}
              </Typography>
            </Grid>

            <Grid item>
              <Typography component="p" variant="subtitle2">
                {checkpointLocationDisplay(checkpoint)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}

      {showExpandButton && (
        <Fragment>
          <div className={`${!expanded && classes.fade}`}></div>
          <Grid container alignItems="center">
            <Grid item className={classes.expandTrackingContainer} onClick={() => setExpanded(!expanded)}>
              {!expanded ? (
                <div className={classes.expandTracking}>
                  <Typography component="span" variant="h2">
                    <FormattedMessage
                      id="tracking-details.show-more"
                      defaultMessage="More tracking history"
                    ></FormattedMessage>
                  </Typography>
                  <ExpandMore />
                </div>
              ) : (
                <div className={classes.expandTracking}>
                  <Typography component="span" variant="h2">
                    <FormattedMessage
                      id="tracking-details.show-less"
                      defaultMessage="Hide tracking history"
                    ></FormattedMessage>
                  </Typography>
                  <ExpandLess />
                </div>
              )}
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Container>
  );
}
