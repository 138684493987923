/* eslint-disable sonarjs/cognitive-complexity */

import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContactInfoDialog from 'components/ContactInfoDialog';
import COLORS from 'config/colors';
import { Shipment } from 'services/index.d';

import { useStyles } from './styles';

export type ContactUsProps = React.HTMLAttributes<string> & {
  shipment: Shipment;
};

export default function ContactUs(props: ContactUsProps) {
  const intl = useIntl();
  const classes = useStyles();
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    courier_logo_url,
    courier_cs_url,
    courier_name,
    courier_service,
    tracking_number,
    courier_tracking_url,
    show_contact_courier,
    show_contact,
    btn_background_color,
    btn_text_color,
    company_phone_number,
    company_contact_email,
    company_cs_url,
  } = props.shipment;
  const logoUrl = `https://storage.googleapis.com/easyship-assets/app/courier-logos/${courier_logo_url}-mini.svg`;
  const showContact = show_contact && (company_phone_number || company_contact_email || company_cs_url);

  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container justifyContent={showContact && show_contact_courier ? 'space-between' : 'center'}>
        <Grid item container className={classes.courierName}>
          <img
            className={classes.courierLogo}
            src={logoUrl}
            alt={intl.formatMessage({
              id: `contact-us.courier-logo`,
              defaultMessage: 'courier logo',
            })}
          ></img>
          <Typography component="p" variant="body2">
            {courier_name} {courier_service}
          </Typography>
        </Grid>
        <Grid item className={`${!isNarrow && classes.verticalAlign}`}>
          <Typography component="span" variant="body2">
            <FormattedMessage id="contact-us.tracking-number" defaultMessage="Tracking No: "></FormattedMessage>
            {courier_tracking_url ? (
              <a className={classes.link} href={courier_tracking_url} target="_blank" rel="noopener noreferrer">
                {tracking_number}
              </a>
            ) : (
              tracking_number
            )}
          </Typography>
        </Grid>
        {isNarrow ? (
          <>
            {show_contact_courier && courier_cs_url && (
              <Grid item>
                <Button
                  href={courier_cs_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.contactCourier}
                  style={{
                    color: btn_text_color || COLORS.white,
                    backgroundColor: btn_background_color,
                    background: btn_background_color || COLORS.greenGradient,
                  }}
                >
                  <FormattedMessage id="contact-us.contact-courier" defaultMessage="Contact Courier"></FormattedMessage>
                </Button>
              </Grid>
            )}
            {showContact && (
              <Grid item>
                <Button onClick={() => setOpenContactDialog(true)}>
                  <FormattedMessage id="contact-us.contact-us" defaultMessage="Contact Us"></FormattedMessage>
                </Button>
                <ContactInfoDialog
                  shipment={props.shipment}
                  open={openContactDialog}
                  onClose={() => setOpenContactDialog(false)}
                />
              </Grid>
            )}
          </>
        ) : (
          <Grid
            container
            justifyContent={showContact && show_contact_courier ? 'space-between' : 'center'}
            style={{ marginTop: 10 }}
          >
            {showContact && (
              <>
                <Button onClick={() => setOpenContactDialog(true)} style={{ padding: 0 }}>
                  <FormattedMessage id="contact-us.contact-us" defaultMessage="Contact Us"></FormattedMessage>
                </Button>
                <ContactInfoDialog
                  shipment={props.shipment}
                  open={openContactDialog}
                  onClose={() => setOpenContactDialog(false)}
                />
              </>
            )}
            {show_contact_courier && courier_cs_url && (
              <Button
                href={courier_cs_url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: btn_text_color || COLORS.white,
                  backgroundColor: btn_background_color,
                  background: btn_background_color || COLORS.greenGradient,
                }}
              >
                <FormattedMessage id="contact-us.contact-courier" defaultMessage="Contact Courier"></FormattedMessage>
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
