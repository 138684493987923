import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { Shipment } from 'services/index.d';
import { LIMITED_TRACKING_RATING, lightCourierServiceNames } from '../../screens/TrackingScreen/TrackingScreen';

interface AlertProps {
  shipment: Shipment;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

export default function AlertMessage({ shipment }: AlertProps) {
  const classes = useStyles();
  const isUspsLightweight =
    shipment?.courier_name === 'USPS' &&
    lightCourierServiceNames.includes(shipment?.courier_service) &&
    shipment?.courier_tracking_rating < LIMITED_TRACKING_RATING;
  const postalServiceTypeIds = [2, 3];
  const showPostalDisclaimer =
    shipment?.service_type_id &&
    postalServiceTypeIds.includes(shipment.service_type_id) &&
    !shipment.no_postal_tracking &&
    shipment?.courier_tracking_rating < LIMITED_TRACKING_RATING;

  const renderCourierLink = (chunks: string) => (
    <a href={shipment.courier_tracking_url} target="_blank" rel="noopener noreferrer" className={classes.link}>
      {chunks}
    </a>
  );

  return (
    <>
      {isUspsLightweight && (
        <MuiAlert icon={false}>
          <Typography component="span" variant="body2">
            <FormattedMessage
              id="tracking-details.post-office-msg.usps-lightweight"
              defaultMessage="This shipment was sent via postal carrier offering limited tracking. If the shipment has not arrived after 10 days, we advise contacting your vendor. Unfortunately, we are unable to assist with tracing the shipment."
            />
          </Typography>
        </MuiAlert>
      )}

      {showPostalDisclaimer && !isUspsLightweight && (
        <MuiAlert icon={false}>
          <Typography component="span" variant="body2">
            <FormattedMessage
              id="tracking-details.post-office-msg.limited-tracking"
              defaultMessage="This shipment was sent via a postal carrier offering limited tracking."
            />
          </Typography>

          <Typography component="span" variant="body2">
            {shipment.courier_tracking_url ? (
              <FormattedMessage
                id="tracking-details.post-office-msg.any-delays"
                defaultMessage=" For any delays, please refer to the information provided <a>here</a>."
                values={{
                  a: renderCourierLink,
                }}
              ></FormattedMessage>
            ) : (
              <FormattedMessage
                id="tracking-details.post-office-msg.contact-post-office"
                defaultMessage=" For any delays, please contact your local post office and provide the tracking number {tracking_number}."
                values={{
                  tracking_number: shipment.tracking_number,
                }}
              />
            )}
          </Typography>
        </MuiAlert>
      )}
    </>
  );
}
