type StringOrNull = StringOrNull | null;

export enum ShipmentStatus {
  Prep = '0-prep',
  ReadyToShip = '1-ready-to-ship',
  InTransit = '2-in-transit',
  Issue = '3-issue',
  OutForDelivery = '3-out-for-delivery',
  Completed = '4-completed',
  Delivered = '4-delivered',
}

export enum CallToActionButtonTypes {
  BookNow = 'Book Now',
  BuyNow = 'Buy Now',
  CallNow = 'Call Now',
  ContactUs = 'Contact Us',
  LearnMore = 'Learn More',
  SeeOffer = 'See Offer',
  SignUp = 'Sign Up',
  ShopNow = 'Shop Now',
  CallNow = 'Call Now',
  UseApp = 'Use App',
  VisitUs = 'Visit Us',
  LeaveReview = 'Leave a Review',
  LeaveComment = 'Leave a Comment',
}

export type Shipment = {
  redirect_url: StringOrNull;
  easyship_company_id: StringOrNull;
  easyship_shipment_id: StringOrNull;
  tracking_number: string;
  expected_delivery_date: StringOrNull;
  courier_logo_url: StringOrNull;
  effective_incoterms: 'DDU' | 'DDP' | null;
  estimated_import_duty: StringOrNull;
  estimated_import_tax: StringOrNull;
  courier_name?: StringOrNull;
  courier_service?: StringOrNull;
  courier_cs_url?: StringOrNull;
  service_type_id?: number;
  no_postal_tracking?: boolean;
  last_status_message: {
    id: number;
    name: StringOrNull;
    subtitle: StringOrNull;
    progress_bar_class: ShipmentStatus; // 0-prep, 1-ready-to-ship, 2-in-transit, 3-issue, 3-out-for-delivery, 4-completed, 4-delivered
    status_color: StringOrNull; // #FFFFFF
    alert_message: StringOrNull;
  };
  track_dates: {
    dispatched: StringOrNull;
    pending: StringOrNull;
    out_for_delivery: StringOrNull;
    delivered: StringOrNull;
    expired: StringOrNull;
  };
  had_delay: boolean;
  origin_country: StringOrNull; // origin country name
  is_cloud: boolean;
  company_name: StringOrNull;
  company_url: StringOrNull;
  company_cs_url: StringOrNull;
  company_logo_url: StringOrNull;
  company_phone_number: StringOrNull;
  company_contact_email: StringOrNull;
  company_footnote: StringOrNull;
  company_social_media: {
    facebook_url: StringOrNull;
    twitter_url: StringOrNull;
    instagram_url: StringOrNull;
    youtube_url: StringOrNull;
  };
  show_header: boolean; // Default false
  show_contact: boolean; // Default true
  show_estimated_dlt: boolean; // Default true
  show_status_icon: boolean; // Default true
  show_contact_courier: boolean; // Default true
  btn_background_color: StringOrNull; // #FFFFFF
  btn_text_color: StringOrNull; // #FFFFFF
  tracking_page_advertising: {
    link_url: StringOrNull;
    call_to_action: CallToActionButtonTypes | null;
  };
  tracking_page_ad: {
    asset_url: StringOrNull; // only show when company is not CHK129189 or platform is one of [Indiegogo, Kickstarter, BackerKit]
  };
  display_request_update_button: boolean;
  display_update_pending_message: boolean;
  min_delivery_time?: StringOrNull; // "1"
  max_delivery_time?: StringOrNull; // "8"
  destination_city: string;
  destination_state?: StringOrNull;
  destination_country: {
    name: string; // shipment destination country name
  };
  checkpoints: Checkpoint[];
  courier_tracking_url: StringOrNull;
  courier_tracking_rating: number;
  local_tracking_number: StringOrNull;
  insurance_requested_by_buyer: boolean; // Default false
};

export type Checkpoint = {
  order_number: number;
  handler: StringOrNull;
  message: StringOrNull;
  location: StringOrNull;
  city: StringOrNull;
  country_name: StringOrNull;
  country_iso3: StringOrNull;
  coordinates: StringOrNull;
  state: StringOrNull;
  zip: StringOrNull;
  checkpoint_time: StringOrNull;
  display_checkpoint_time: StringOrNull; // Jan 30, 2019 6:22 AM
  handler_created_at: StringOrNull;
  status_message_id: number;
};

export type ShopifyResponse = {
  easyship_shipment_id?: string;
  label_state?: string;
  label_url?: string;
};

export type CourierSurvey = {
  id: string;
  version: string;
  is_complete: boolean;
  comment: string | null;
  overall_experience_score: string | null;
  total_score?: string | null;

  dropoff_score?: string | null;
  pickup_score?: string | null;
  customer_service_score?: string | null;

  delivery_time_score?: string | null;
  handling_quality_score?: string | null;
};

export type CourierIdentity = {
  name: string;
  logo_url: string;
};

export type CourierSurveyResponse = {
  courier: CourierIdentity;
  company?: { send_receiver_survey: boolean };
  sender_survey?: CourierSurvey;
  receiver_survey?: CourierSurvey;
};

export type UnsubscribeOrigin = 'survey' | 'tracking';

export type UnsubscribeRequest = {
  unsubscribed_email: {
    email_id: string; // uuid for a shipment record
    origin: UnsubscribeOrigin;
  };
};

export type UnsubscribeResponse = {
  status: string;
  id?: string;
  email?: string;
  error?: string;
};

export type UnsubscribeFeedback = {
  unsubscribed_email: {
    id: string; // uuid for an unsubscribed_email record
    reason?: string;
    comment?: string;
  };
};
