/* eslint-disable sonarjs/cognitive-complexity */

import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { MixpanelContext } from 'config/analytics';

import Header from 'components/Header';
import TrackingDetails from 'components/TrackingDetails';
import ContactUs from 'components/ContactUs';
import Advertisement from 'components/Advertisement';
import Footer from 'components/Footer';
import ProgressIndicator from 'components/ProgressIndicator';
import { Shipment } from 'services/index.d';

import TrackingService from 'services/tracking';
import RecaptchaService from 'services/recaptcha';

export const LIMITED_TRACKING_RATING = 2;

export const lightCourierServiceNames = ['LightweightStandard', 'LightweightEconomy'];

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 550,
    margin: '20px auto 0',
  },
}));

interface ITrackingScreenState {
  keyword?: string;
  companyId?: string;
}

export default function TrackingScreen() {
  const classes = useStyles();
  const mixpanel = useContext(MixpanelContext);
  const history = useHistory();
  const { keyword, companyId } = (useParams() || {}) as ITrackingScreenState;
  const [shipment, setShipment] = useState<Shipment>();

  const isUSPSLightweight =
    shipment?.courier_name === 'USPS' &&
    lightCourierServiceNames.includes(shipment?.courier_service) &&
    shipment?.courier_tracking_rating < LIMITED_TRACKING_RATING;

  useEffect(() => {
    if (!keyword) {
      history.push('/');
      return;
    }

    RecaptchaService.execute().then((recaptchaToken: string) => {
      if (keyword === 'preview' && companyId) {
        TrackingService.findPreview(companyId, recaptchaToken).then((shipment) => {
          if (!shipment) throw new Error();
          setShipment(shipment);
        });
      } else {
        TrackingService.find(keyword, recaptchaToken)
          .then((shipment) => {
            if (!shipment) throw new Error();
            if (shipment.redirect_url) return;

            setShipment(shipment);
          })
          .catch(() => history.push(`/`, { query: keyword, shipmentNotFound: true }));
      }
    });
  }, [keyword, companyId, history, mixpanel]);

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Results | Track My Shipment</title>
      <link rel="canonical" href={`https://www.trackmyshipment.co/shipment-tracking/${keyword}`} />
      <meta name="description" content="Track shipments from almost any express courier from store to door" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://www.trackmyshipment.co/shipment-tracking/${keyword}`} />
      <meta property="og:title" content="Results | Track My Shipment" />
      <meta property="og:description" content="Track shipments from almost any express courier from store to door" />
      <meta property="og:image" content="https://www.trackmyshipment.co/images/1-ready-to-ship.svg" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`https://www.trackmyshipment.co/shipment-tracking/${keyword}`} />
      <meta property="twitter:title" content="Results | Track My Shipment" />
      <meta property="twitter:description" content="Track shipments from almost any express courier from store to door" />
      <meta property="twitter:image" content="https://www.trackmyshipment.co/images/1-ready-to-ship.svg" />
    </Helmet>
      {shipment ? (
        <Container className={classes.root}>
          <Header shipment={shipment} />
          <TrackingDetails shipment={shipment} />
          {!isUSPSLightweight && <ContactUs shipment={shipment} />}
          <Advertisement shipment={shipment} />
          <Footer shipment={shipment} />
        </Container>
      ) : (
        <div>
          <ProgressIndicator centered size={3} />
        </div>
      )}
    </>
  );
}
