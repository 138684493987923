import { Shipment } from '../index';

const API_BASE = process.env.REACT_APP_API_BASE;

async function find(keyword: string, recaptchaToken: string): Promise<Shipment> {
  const options = {
    method: 'GET',
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  };

  const res = await fetch(`${API_BASE}/v1/track/${keyword}`, options);
  return res.ok ? res.json() : null;
}

async function findPreview(companyId: string, recaptchaToken: string) {
  const options = {
    method: 'GET',
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  };

  const res = await fetch(`${API_BASE}/v1/track/preview/${companyId}`, options);
  return res.ok ? res.json() : null;
}

async function shopify(controller: 'track' | 'label_info', id: string, shop: string): Promise<Shipment> {
  const res = await fetch(`${API_BASE}/v1/shopify/${controller}?id=${id}&shop=${shop}`);
  return res.ok ? res.json() : null;
}

const TrackingResource = { find, findPreview, shopify };
export default TrackingResource;
