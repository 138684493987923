import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Divider } from '@material-ui/core';

import ProgressBar from 'components/ProgressBar';
import History from 'components/History';

import { Shipment } from 'services/index.d';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '25px 35px 15px',
    minWidth: '100%',

    '& .MuiDivider-root': {
      margin: '0 -35px',
      backgroundColor: theme?.palette?.sky?.main,
    },

    [theme.breakpoints.down('xs')]: {
      padding: '12px 0 18px',

      '& .MuiDivider-root': {
        margin: '0',
      },
    },
  },
}));

export type TrackingDetailsProps = React.HTMLAttributes<string> & {
  shipment: Shipment;
};

export default function TrackingDetails({ shipment }: TrackingDetailsProps) {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <ProgressBar shipment={shipment} />
      {shipment.checkpoints.length ? (
        <>
          <Divider />
          <History shipment={shipment} />
        </>
      ) : null}
    </Paper>
  );
}
