import { useRef, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import cta from './cta.json';

import { Shipment } from 'services/index.d';

export type AdvertisementProps = React.HTMLAttributes<string> & {
  shipment: Shipment;
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    textAlign: 'center',

    '& img': {
      borderRadius: 8,
      maxWidth: '100% !important',
      height: 'auto',
      width: 'auto',
    },
  },
  buttonContainer: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    bottom: 25,
    display: 'flex',
    justifyContent: 'center',

    '& .MuiButtonBase-root': {
      margin: '0 auto',
      height: 35,
      minWidth: 150,
    },

    [theme.breakpoints.down('xs')]: {
      position: 'initial',
      marginTop: 5,

      '& .MuiGrid-item': {
        width: '100%',
      },

      '& .MuiButtonBase-root': {
        margin: '0',
        width: '100%',
      },
    },
  },
  ctaButton: {
    boxShadow: '0px 2px 4px rgba(40, 78, 102, 0.2) !important',
    background: 'transparent linear-gradient(180deg, #FFFFFF 0%, #F4F6F8 100%) 0% 0% no-repeat padding-box',
  },
  positionRelative: {
    position: 'static',
    marginTop: 15,
  },
}));

export default function Advertisement(props: AdvertisementProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { tracking_page_ad, tracking_page_advertising } = props.shipment;
  const ctaTranslation = tracking_page_advertising.call_to_action
    ? cta[tracking_page_advertising.call_to_action]
    : null;
  const [adImgHeight, setAdImgHeight] = useState(0);
  const RE_PROTOCOL = /^http/;
  const protocolChecker = (url: string) => (RE_PROTOCOL.test(url) ? url : `//${url}`);
  const imgEl = useRef(null) as any;

  useEffect(() => {
    let cancelled = false;
    const getHeight = () => {
      const { current } = imgEl; // `ref` will never be falsy
      if (!current || !current.clientHeight) {
        if (!cancelled) {
          requestAnimationFrame(getHeight);
        }
      } else {
        setAdImgHeight(current.naturalHeight);
      }
    };
    getHeight();
    return () => {
      cancelled = true;
    };
  }, []);

  return (
    <>
      {tracking_page_ad.asset_url && (
        <div className={classes.root}>
          <img
            src={tracking_page_ad.asset_url}
            alt={intl.formatMessage({
              id: `advertisement.shipper-ad`,
              defaultMessage: 'Shipper Advertisement',
            })}
            ref={imgEl}
          />
          {ctaTranslation && tracking_page_advertising.link_url && (
            <div className={`${classes.buttonContainer} ${adImgHeight <= 100 && classes.positionRelative}`}>
              <Button
                className={classes.ctaButton}
                href={protocolChecker(tracking_page_advertising.link_url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage(ctaTranslation)}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
