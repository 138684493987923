import { createContext } from 'react';

import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';

// MIXPANEL
if (process.env.REACT_APP_MIXPANEL_TOKEN) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
if (process.env.NODE_ENV !== 'production') mixpanel.disable();
export const MixpanelContext = createContext(mixpanel);
export const mixpanelLibrary = mixpanel;

// GOOGLE TAG MANAGER
if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development' && process.env.REACT_APP_GOOGLE_TAG) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG,
    events: {
      sendUserInfo: 'userInfo',
    },
  };
  TagManager.initialize(tagManagerArgs);
}
