import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0 0 20px',

    [theme.breakpoints.down('xs')]: {
      padding: '0 15px 15px',
    },

    '& .MuiGrid-item': {
      maxHeight: 62,

      [theme.breakpoints.down('xs')]: {
        maxHeight: 'none',
      },
    },

    '& .MuiLinearProgress-root': {
      height: 15,
      width: '100%',
      borderRadius: 15,
      margin: '20px 0',
    },

    '& .MuiLinearProgress-colorPrimary': {
      background: theme.palette.background.default,
    },

    '& .grey': {
      '& .MuiLinearProgress-barColorPrimary': {
        background: '#DFE3E8 0% 0% no-repeat padding-box;',
      },
    },

    '& .gradientGrey': {
      '& .MuiLinearProgress-barColorPrimary': {
        background: 'linear-gradient(270deg, #454F5B 0%, #637381 100%) 0% 0% no-repeat padding-box;',
      },
    },

    '& .yellow': {
      '& .MuiLinearProgress-barColorPrimary': {
        background: 'linear-gradient(270deg, #F7B500 0%, #FFDF88 100%) 0% 0% no-repeat padding-box;',
      },
    },

    '& .blue': {
      '& .MuiLinearProgress-barColorPrimary': {
        background: 'linear-gradient(270deg, #1F9AEF 0%, #94CDF4 100%) 0% 0% no-repeat padding-box;',
      },
    },

    '& .teal': {
      '& .MuiLinearProgress-barColorPrimary': {
        background: 'linear-gradient(270deg, #4CCACE 0%, #A5E4E6 100%) 0% 0% no-repeat padding-box;',
      },
    },

    '& .green': {
      '& .MuiLinearProgress-barColorPrimary': {
        background: 'linear-gradient(270deg, #28A17B 0%, #50D4A4 100%) 0% 0% no-repeat padding-box;',
      },
    },
  },
  progressBar: {
    width: '100%',
  },
  statusContainer: {
    marginBottom: 10,
  },
  status: {
    margin: '7px 0 5px',
  },
  icon: {
    marginRight: 10,
    width: 62,
    height: 62,

    '& div': {
      width: 62,
      height: 62,
    },
  },
  destination: {
    width: '100%',
  },
  bigFont: {
    fontSize: 16,
  },
  floatRight: {
    float: 'right',
    margin: 0,
  },
}));
