import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Typography, TextField, Grid, Button, Fade } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage, useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import COLORS from 'config/colors';

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    textAlign: 'center',
    paddingTop: 150,
    marginBottom: 65,

    '& img': {
      width: 200,
    },
  },
  form: {
    width: 575,
    margin: '0 auto',

    '& .MuiOutlinedInput-root': {
      width: 350,
      height: 40,
      margin: '0 25px 0 0',
    },

    '& .MuiButton-root': {
      width: 200,
    },

    [theme.breakpoints.down('xs')]: {
      width: 250,

      '& .MuiOutlinedInput-root': {
        width: 250,
        height: 40,
        margin: '0 0 5px',
      },

      '& .MuiButton-root': {
        width: 250,
      },
    },
  },
  formContainer: {
    margin: '5px 0 15px',
  },
  trackButton: {
    backgroundImage: `linear-gradient(-180deg, ${COLORS.greenNormal} 0%, ${COLORS.greenDark} 100%)`,
    color: COLORS.white,

    '&:hover': {
      backgroundImage: 'none',
      backgroundColor: COLORS.greenNormal,
    },
  },
  example: {
    fontSize: 16,
    color: COLORS.inkLight,
  },
}));

interface IHomeScreenState {
  shipmentNotFound?: boolean;
  query: string;
}

export default function HomeScreen() {
  const location: { state?: IHomeScreenState } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const intl = useIntl();
  const query = location?.state?.query;

  useEffect(() => {
    setTimeout(clearAlertMessage, 5000);
  });

  function clearAlertMessage() {
    if (location.state && location.state.shipmentNotFound) {
      history.replace('/', {});
    }
  }

  async function track(e: any) {
    e.preventDefault();

    if (keyword) {
      return history.push(`/shipment-tracking/${keyword}`);
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search | Track My Shipment</title>
        <link rel="canonical" href="https://www.trackmyshipment.co/" />
        <meta name="description" content="Track shipments from almost any express courier from store to door" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.trackmyshipment.co/" />
        <meta property="og:title" content="Search | Track My Shipment" />
        <meta property="og:description" content="Track shipments from almost any express courier from store to door" />
        <meta property="og:image" content="https://www.trackmyshipment.co/images/1-ready-to-ship.svg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.trackmyshipment.co/" />
        <meta property="twitter:title" content="Search | Track My Shipment" />
        <meta property="twitter:description" content="Track shipments from almost any express courier from store to door" />
        <meta property="twitter:image" content="https://www.trackmyshipment.co/images/1-ready-to-ship.svg" />
      </Helmet>
      <div className={classes.logoContainer}>
        <img
          src="/images/track-my-shipment-logo.svg"
          alt={intl.formatMessage({ id: 'home.img-alt', defaultMessage: 'Track My Shipment' })}
        />
      </div>
      <div className={classes.form}>
        <Typography variant="body1">
          <FormattedMessage id="home.tracking-number" defaultMessage="Tracking number"></FormattedMessage>
        </Typography>
        <form onSubmit={track}>
          <Grid container direction={isNarrow ? 'column' : 'row'} className={classes.formContainer}>
            <Grid item>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                onChange={(event) => setKeyword(event.target.value)}
              />
            </Grid>
            <Grid item>
              <Button variant="text" className={classes.trackButton} type="submit">
                <FormattedMessage id="home.track" defaultMessage="Track"></FormattedMessage>
              </Button>
            </Grid>
          </Grid>
        </form>
        {location?.state?.shipmentNotFound && (
          <Fade in={true}>
            <MuiAlert severity="error" icon={false}>
              {query ? (
                <FormattedMessage
                  id="home.not-found"
                  defaultMessage="We couldn't find any shipment with tracking number {query}. You may try again with a different number."
                  values={{ query }}
                />
              ) : (
                <FormattedMessage
                  id="home.not-found-no-query"
                  defaultMessage="Sorry, we couldn't find the shipment you requested."
                />
              )}
            </MuiAlert>
          </Fade>
        )}
        <p className={classes.example}>
          <FormattedMessage id="home.example" defaultMessage="Example: 774906988706"></FormattedMessage>
        </p>
      </div>
    </>
  );
}
