import { useTheme } from '@material-ui/core/styles';
import { Container, Grid, Typography, LinearProgress } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './styles';

import { Shipment } from 'services/index.d';
import barStates from './bar-states.json';

import AlertMessage from 'components/AlertMessage';
import StatusIcon from 'components/StatusIcon';

type ProgressBarProps = React.HTMLAttributes<string> & {
  shipment: Shipment;
};

export default function ProgressBar({ shipment }: ProgressBarProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const status = shipment.last_status_message.progress_bar_class;
  const barState = barStates[status] || {
    percent: 100,
    color: 'gradientGrey',
    showEtd: false,
  };
  const showETD = shipment.show_estimated_dlt && shipment.expected_delivery_date && barState.showEtd;

  let { destination_city, destination_state, destination_country, min_delivery_time, max_delivery_time } = shipment;
  const { name, subtitle } = shipment.last_status_message;
  const destination = [destination_city, destination_state, destination_country.name].filter((v) => v).join(', ');

  return (
    <Container className={classes.root}>
      <Grid container wrap="nowrap">
        {!isNarrow && shipment.show_status_icon && (
          <Grid item className={classes.icon}>
            <StatusIcon status={status} />
          </Grid>
        )}
        <Grid item className={classes.statusContainer}>
          <Typography component="p" variant="h4" className={classes.status}>
            {name}
          </Typography>
          <Typography component="p" variant="body2">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap">
        {isNarrow && shipment.show_status_icon && (
          <Grid item className={classes.icon}>
            <StatusIcon status={status} />
          </Grid>
        )}
        <Grid item className={`${classes.progressBar} ${barState.color}`}>
          <LinearProgress value={barState.percent} variant="determinate" />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={1}>
        {showETD && (
          <Grid item className={classes.destination}>
            <Typography component="span" variant="body2" className={classes.status}>
              <FormattedMessage
                id="tracking-details.expected-delivery-by"
                defaultMessage="Expected delivery by: "
              ></FormattedMessage>
            </Typography>
            &nbsp;&nbsp;
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.status} ${isNarrow && classes.bigFont} ${isNarrow && classes.floatRight}`}
            >
              <FormattedDate
                value={new Date(shipment.expected_delivery_date)}
                weekday="short"
                day="2-digit"
                month="short"
                year="numeric"
              />
            </Typography>
          </Grid>
        )}
        {min_delivery_time && max_delivery_time && barState.percent < 100 && (
          <Grid item className={classes.destination}>
            <Typography component="span" variant="body2">
              <FormattedMessage
                id="tracking-details.ebay-delivery-time"
                defaultMessage="Expected delivery within: "
              ></FormattedMessage>
            </Typography>
            &nbsp;&nbsp;
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.status} ${isNarrow && classes.floatRight}`}
            >
              <FormattedMessage
                id="tracking-details.ebay-delivery-time-range"
                defaultMessage="{min_day} - {max_day} days"
                values={{
                  min_day: min_delivery_time,
                  max_day: max_delivery_time,
                }}
              ></FormattedMessage>
            </Typography>
          </Grid>
        )}
        <Grid item className={classes.destination}>
          <Typography component="span" variant="body2">
            <FormattedMessage id="tracking-details.destination" defaultMessage="Destination:"></FormattedMessage>
          </Typography>
          &nbsp;&nbsp;
          <Typography
            component="span"
            variant="subtitle1"
            className={`${classes.status} ${isNarrow && classes.floatRight}`}
          >
            {destination}
          </Typography>
        </Grid>
      </Grid>
      <AlertMessage shipment={shipment} />
    </Container>
  );
}
