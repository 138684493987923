import { StyleRules, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { AlertClassKey } from '@material-ui/lab';
import COLORS from './colors';

const BOX_SHADOW = '0 4px 4px 0 rgba(40, 78, 102, 0.20)';
const placeholderVisible = {
  opacity: '1 !important',
};

interface LabComponentsOptions {
  MuiAlert?: Partial<StyleRules<AlertClassKey, {}>>;
}

const labComponentsOverride: LabComponentsOptions = {
  MuiAlert: {
    root: {
      padding: '10px 15px',
      borderRadius: 8,
      marginTop: 20,
    },
    standardSuccess: {
      backgroundColor: COLORS.tealLight,
    },
    standardError: {
      backgroundColor: COLORS.redLight,
    },
    icon: {
      padding: 0,
      fontSize: 13,
      marginRight: 10,
    },
    message: {
      color: COLORS.inkNormal,
      fontSize: 13,
      padding: 0,
    },
  },
};

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Karla',
    h1: {
      fontSize: 20,
      lineHeight: '32px',
      color: COLORS.inkDarkest,
    },
    h2: {
      fontSize: 16,
      color: COLORS.inkDarkest,
    },
    h3: {
      fontSize: 16,
      color: COLORS.inkLight,
    },
    h4: {
      fontSize: 16,
      color: COLORS.inkDark,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: 13,
      lineHeight: '20px',
      color: COLORS.inkDark,
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: 13,
      lineHeight: '20px',
      color: COLORS.inkLight,
    },
    body1: {
      fontSize: 13,
      lineHeight: '20px',
      color: COLORS.inkDark,
    },
    body2: {
      fontSize: 13,
      lineHeight: '20px',
      color: COLORS.inkNormal,
    },
    caption: {
      fontSize: 13,
      lineHeight: '16px',
      color: COLORS.inkFaded,
      fontStyle: 'italic',
    },
  },

  palette: {
    background: { default: COLORS.skyNormal },
    primary: {
      main: COLORS.greenNormal,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.blueNormal,
      contrastText: COLORS.white,
    },
    success: {
      main: COLORS.success,
    },
    error: {
      main: COLORS.failure,
    },

    teal: {
      main: COLORS.tealNormal,
      dark: COLORS.tealDark,
      light: COLORS.tealLight,
      contrastText: COLORS.white,
    },
    blue: {
      main: COLORS.blueNormal,
      dark: COLORS.blueDark,
      light: COLORS.blueLight,
    },
    green: {
      main: COLORS.greenNormal,
      dark: COLORS.greenDark,
      light: COLORS.greenLight,
    },
    yellow: {
      main: COLORS.yellowNormal,
      dark: COLORS.yellowDark,
      light: COLORS.yellowLight,
    },
    red: {
      main: COLORS.redNormal,
      dark: COLORS.redDark,
      light: COLORS.redLight,
    },
    ink: {
      main: COLORS.inkNormal,
      dark: COLORS.inkDark,
      light: COLORS.inkLight,
    },
    sky: {
      main: COLORS.skyNormal,
      dark: COLORS.skyDark,
      light: COLORS.skyLight,
    },
  },

  overrides: {
    ...labComponentsOverride,

    MuiChip: {
      root: {
        height: 16,
        borderRadius: 3,
        fontSize: 12,
        fontWeight: 'normal',
        backgroundColor: COLORS.inkLight,
      },
      label: {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },

    MuiOutlinedInput: {
      root: {
        marginTop: 22,
        backgroundColor: 'white',

        '&, &.Mui-focused, &:hover': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.skyDark,
            borderWidth: '1px !important',
          },
        },

        /** This matches the `disabled` style in easyship-dashboard, but it doesn't work well without a white background */
        '&.Mui-disabled': {
          color: COLORS.inkLight,
          backgroundColor: COLORS.skyLight,
        },

        '&.Mui-error, &.Mui-error:hover': {
          borderColor: `${COLORS.redDark} !important`,
          backgroundColor: COLORS.redLight,

          '& fieldset': {
            borderColor: COLORS.redDark,
          },
        },
      },

      input: {
        padding: 10,
        color: COLORS.inkDark,

        // NOT WORKING. Need to use InputLabelProps={{ shrink: true }}
        'label[data-shrink=false] + $formControl &': {
          '&::placeholder': placeholderVisible,
          '&::-webkit-input-placeholder': placeholderVisible,
          '&::-moz-placeholder': placeholderVisible, // Firefox 19+
          '&:-ms-input-placeholder': placeholderVisible, // IE11
          '&::-ms-input-placeholder': placeholderVisible, // Edge
        },
      },

      notchedOutline: {
        '& legend': {
          width: 0,
        },
      },
    },

    MuiInputAdornment: {
      root: {
        backgroundColor: 'inherit',
      },
    },

    MuiInputLabel: {
      outlined: {
        '&, &.MuiInputLabel-shrink': {
          transform: 'translate(0px, 0px)',
        },
      },
    },

    MuiFormLabel: {
      root: {
        '&, &$disabled, &$focused': {
          color: COLORS.inkDark,
        },

        '&$error': {
          color: COLORS.inkDark,
        },

        '&$disabled': {
          color: `${COLORS.inkDark} !important`,
        },
      },
    },

    MuiRadio: {
      root: {
        color: COLORS.skyDarkest,
        '&$checked': {
          color: COLORS.inkDark,
        },
      },
    },

    MuiSvgIcon: {
      colorError: {
        color: COLORS.redDark,
      },
    },

    MuiButton: {
      root: {
        borderRadius: 4,
        fontFamily: 'Karla-Bold',
        transition: 'none',

        '&:hover': {
          backgroundImage: 'none',
          backgroundColor: 'none',
          boxShadow: 'none',
        },

        '&.MuiButton-contained': {
          height: 35,
          backgroundImage: `linear-gradient(-180deg, ${COLORS.white} 0%, ${COLORS.skyNormal} 100%)`,
          color: COLORS.inkNormal,
          boxShadow: '0px 2px 4px rgba(40, 78, 102, 0.2)',

          '&.MuiButton-containedPrimary': {
            backgroundImage: `linear-gradient(-180deg, ${COLORS.greenNormal} 0%, ${COLORS.greenDark} 100%)`,
            color: COLORS.white,
          },

          '&.MuiButton-containedSecondary': {
            backgroundImage: `linear-gradient(-180deg, ${COLORS.blueNormal} 0%, ${COLORS.blueDark} 100%)`,
            color: COLORS.white,
          },

          '&.Mui-disabled': {
            backgroundImage: 'none',
            backgroundColor: COLORS.skyDark,
          },

          '&:hover': {
            backgroundImage: 'none',
            backgroundColor: 'COLORS.white',
            boxShadow: '0px 2px 4px rgba(40, 78, 102, 0.2)',

            '&.MuiButton-containedPrimary': {
              backgroundColor: COLORS.greenNormal,
            },

            '&.MuiButton-containedSecondary': {
              backgroundColor: COLORS.blueNormal,
            },
          },

          '&:active': {
            backgroundImage: 'none !important',
            boxShadow: BOX_SHADOW,
            backgroundColor: COLORS.skyDark,

            '&.MuiButton-containedPrimary': {
              backgroundColor: COLORS.greenDark,
            },

            '&.MuiButton-containedSecondary': {
              backgroundColor: COLORS.blueDark,
            },
          },
        },
      },
      text: {
        padding: '8px 30px',
        color: COLORS.inkNormal,
        boxShadow: 'none',

        '&$disabled': {
          backgroundColor: COLORS.skyDark,
          backgroundImage: 'none',
          color: COLORS.white,
          boxShadow: 'none',
        },
      },
      textPrimary: {
        '&:hover': {
          backgroundImage: 'none',
          backgroundColor: COLORS.skyLight,
        },
      },
      textSecondary: {
        '&:hover': {
          backgroundImage: 'none',
          backgroundColor: COLORS.skyLight,
        },
      }
    },

    MuiList: {
      root: {
        maxHeight: '100%',
        overflowY: 'auto',
      },
    },

    MuiListItem: {
      gutters: {
        paddingLeft: 24,
        paddingRight: 24,
      },
    },

    MuiListItemText: {
      primary: {
        color: COLORS.inkNormal,
      },
      secondary: {
        color: COLORS.inkDarkest,
      },
    },

    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        boxShadow: '0px 10px 20px rgba(40, 78, 102, 0.15)',
        padding: '10px 20px',
        maxWidth: 184,
        color: COLORS.inkNormal,
        borderColor: COLORS.skyNormal,
        fontSize: 13,
        lineHeight: '20px',

        '& a': {
          color: COLORS.blueNormal,
        },
      },
      arrow: { color: 'white' },
    },

    MuiSwitch: {
      root: {
        width: 70,
        height: 50,
        padding: 0,
        display: 'flex',
      },
      switchBase: {
        padding: 2,
        '&$checked': {
          transform: 'translateX(20px)',
          '& $thumb': { backgroundColor: COLORS.tealNormal },
        },
      },
      thumb: {
        width: 16,
        height: 16,
        margin: 15,
        backgroundColor: COLORS.skyDarkest,
        boxShadow: 'none',
      },
      track: {
        width: 40,
        height: 20,
        margin: 15,
        border: 'none',
        boxShadow: '0px 2px 15px rgba(40, 78, 102, 0.1)',
        borderRadius: 10,
        opacity: 1,
        backgroundColor: 'white !important',
      },
    },

    MuiPaper: {
      elevation3: {
        boxShadow: '0px 2px 15px rgba(40, 78, 102, 0.1)',
      },
      rounded: {
        borderRadius: 8,
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(69, 79, 91, 0.5)',
      },
    },

    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },

    MuiStepIcon: {
      root: {
        color: COLORS.skyDarkest,
        '&.MuiStepIcon-active': { color: COLORS.tealNormal },
        '&.MuiStepIcon-completed': { color: COLORS.skyDarkest },
      },
    },

    MuiStepConnector: {
      line: {
        borderColor: COLORS.skyDarkest,
      },
    },
  },
});
