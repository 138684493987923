/* eslint-disable sonarjs/cognitive-complexity, sonarjs/no-duplicate-string */

import { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import ProgressIndicator from 'components/ProgressIndicator';
import SurveyService from 'services/survey';
import { CourierSurvey, CourierIdentity, CourierSurveyResponse } from 'services';
import EmojiQuestionaire from './EmojiQuestionaire';

const queryString = require('query-string');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '20px auto 0',
  },
}));

interface ICourierSurveyScreenState {
  userType: 'sender' | 'receiver';
  surveyId: string;
}

export default function CourierSurveyScreen() {
  const classes = useStyles();
  const history = useHistory();
  const { userType, surveyId } = (useParams() || {}) as ICourierSurveyScreenState;
  const { r, dt } = queryString.parse(useLocation().search);
  const [survey, setSurvey] = useState<CourierSurvey>();
  const [courier, setCourier] = useState<CourierIdentity>();
  const [sendReceiverSurvey, setSendReceiverSurvey] = useState(true);

  useEffect(() => {
    SurveyService.request(userType, surveyId, r || dt)
      .then(({ courier, company, sender_survey, receiver_survey }: CourierSurveyResponse) => {
        setSurvey((sender_survey || receiver_survey) as CourierSurvey);
        setCourier(courier);
        setSendReceiverSurvey(!!company?.send_receiver_survey);
      })
      .catch(() => history.push(`/`));
  }, [userType, surveyId, r, dt, history]);

  const handleSubmit = (form: CourierSurvey) => {
    SurveyService.submit(userType, form).then(() => {
      if (survey) {
        setSurvey({ ...survey, is_complete: true });
      }
    });
  };

  return (
    <Box maxWidth={440} margin="0 auto" padding="0 20px">
      {!survey ? (
        <div>
          <ProgressIndicator centered size={3} />
        </div>
      ) : (
        <Container className={classes.root}>
          <Box justifySelf="center" mt={4} mx="auto">
            {userType === 'sender' && (
              <Typography>
                <FormattedMessage id="survey.sender-prefix" defaultMessage="Survey by"></FormattedMessage>
              </Typography>
            )}
            <img
              src={`/images/${userType === 'sender' ? 'easyship' : 'track-my-shipment'}-logo.svg`}
              style={{ height: 30 }}
              alt="banner"
            />
          </Box>
          <hr style={{ width: '100%', borderBottom: 'thin solid #dfe3e8', borderTop: 'none' }} />

          {survey.is_complete ? (
            <>
              <Box>
                <Typography
                  style={{ fontSize: 20, lineHeight: '32px', color: 'rgba(33,43,54,.7)', textAlign: 'center' }}
                >
                  <FormattedMessage id="survey.thanks" defaultMessage="Thank you for reviewing" />
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Karla-Bold,Helvetica,Arial,sans-serif',
                    fontSize: 20,
                    lineHeight: '32px',
                    color: 'rgba(33,43,54,.7)',
                    textAlign: 'center',
                  }}
                >
                  {courier?.name}
                </Typography>
              </Box>
              <hr style={{ width: '100%', borderBottom: 'thin solid #dfe3e8', borderTop: 'none' }} />
              <Typography
                style={{
                  textAlign: 'center',
                  fontSize: 13,
                  lineHeight: '20px',
                  paddingTop: 20,
                  color: 'rgba(33,43,54,.7)',
                }}
              >
                <FormattedMessage
                  id="survey.appreciate"
                  defaultMessage="We appreciate your feedback! This will provide more visibility to our services."
                />
              </Typography>
            </>
          ) : (
            <>
              {courier && (
                <Box mt={4} mb={6}>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                      <img
                        src={`https://storage.googleapis.com/easyship-assets/app/courier-logos/${courier.logo_url}-mini.svg`}
                        style={{ width: 50, borderRadius: 4 }}
                        alt="courier logo"
                      />
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: 20, lineHeight: '32px', color: 'rgba(33,43,54,.7)' }}>
                        <FormattedMessage id="survey.title" defaultMessage="Rate & Review" />
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Karla-Bold,Helvetica,Arial,sans-serif',
                          fontSize: 20,
                          lineHeight: '32px',
                          color: 'rgba(33,43,54,.7)',
                        }}
                      >
                        {courier.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <EmojiQuestionaire
                userType={userType}
                survey={survey}
                sendReceiverSurvey={sendReceiverSurvey}
                onSubmit={handleSubmit}
              />
            </>
          )}
        </Container>
      )}
    </Box>
  );
}
