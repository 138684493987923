/* eslint-disable sonarjs/cognitive-complexity */

import { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

import ProgressIndicator from 'components/ProgressIndicator';
import TrackingService from 'services/tracking';
import { ShopifyResponse } from 'services';

const queryString = require('query-string');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 550,
    margin: '20px auto 0',
  },
}));

interface IRedirectScreenState {
  action?: 'track' | 'label';
}
type LabelState = 'ready' | 'not_created' | 'failed' | 'pending';

export default function RedirectScreen() {
  const classes = useStyles();
  const history = useHistory();
  const { action } = (useParams() || {}) as IRedirectScreenState;
  const { id, shop } = queryString.parse(useLocation().search);
  const [labelUrl, setLabelUrl] = useState<string>();
  const [labelState, setLabelState] = useState<LabelState>();

  useEffect(() => {
    if (action && id && shop) {
      TrackingService.shopify(action, id, shop)
        .then((res: ShopifyResponse) => {
          switch (action) {
            case 'track':
              if (!res?.easyship_shipment_id) throw new Error();
              history.push(`/shipment-tracking/${res.easyship_shipment_id}`);
              break;

            case 'label':
              if (!res?.label_url) throw new Error();
              setLabelUrl(res.label_url);
              setLabelState('ready');
          }
        })
        .catch(() => history.push(`/`, { shipmentNotFound: true }));
    } else {
      return history.push(`/`, { shipmentNotFound: true });
    }
  }, [action, id, shop, history]);

  return (
    <>
      {labelState ? (
        <Container className={classes.root}>
          {labelState === 'ready' && (
            <>
              <Typography>
                <FormattedMessage
                  id="redirect.ready-headline"
                  defaultMessage="Your shipping documents are ready!"
                ></FormattedMessage>
              </Typography>
              <Typography>
                <FormattedMessage
                  id="redirect.ready-action"
                  defaultMessage="<a>Click here</a> to download your label"
                  values={{
                    a: (chunk: string) => (
                      <a href={labelUrl} target="_blank" rel="noopenner noreferrer" download>
                        {chunk}
                      </a>
                    ),
                  }}
                ></FormattedMessage>
              </Typography>
            </>
          )}

          {labelState === 'not_created' && (
            <>
              <Typography>
                <FormattedMessage
                  id="redirect.not_created-headline"
                  defaultMessage="Seems like your label has not been confirmed yet"
                ></FormattedMessage>
              </Typography>
              <Typography>
                <FormattedMessage
                  id="redirect.not_created-action"
                  defaultMessage="<a>Click here</a> to ship your orders"
                  values={{
                    a: (chunk: string) => (
                      <a href="https://app.easyship.com/multiple" target="_blank" rel="noopenner noreferrer" download>
                        {chunk}
                      </a>
                    ),
                  }}
                ></FormattedMessage>
              </Typography>
            </>
          )}

          {['failed', 'pending'].includes(labelState) && (
            <>
              <Typography>
                <FormattedMessage
                  id="redirect.pending-headline"
                  defaultMessage="Seems like the label is not yet ready for this shipment"
                ></FormattedMessage>
              </Typography>
              <Typography>
                <FormattedMessage
                  id="redirect.pending-action"
                  defaultMessage="<a>Click here</a> to check the status of your shipments"
                  values={{
                    a: (chunk: string) => (
                      <a href="https://app.easyship.com/shipments" target="_blank" rel="noopenner noreferrer" download>
                        {chunk}
                      </a>
                    ),
                  }}
                ></FormattedMessage>
              </Typography>
            </>
          )}
        </Container>
      ) : (
        <div>
          <ProgressIndicator centered size={3} />
        </div>
      )}
    </>
  );
}
