import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Box, FormLabel, FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles({
  label: {
    fontSize: 16,
    lineHeight: '24px',
    color: 'rgba(33,43,54,.7)',
    fontWeight: 400,
    textAlign: 'center',
  },
  helper: {
    color: '#919eab',
    textAlign: 'center',
    maxWidth: '100vw',
    margin: 'auto',
  },
  buttonGroup: {
    '& button.MuiToggleButton-root': {
      width: 80,
      border: 'none',

      '&.Mui-selected, &:hover': {
        backgroundColor: 'transparent',
      },

      '& .MuiToggleButton-label': {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '20px',
        color: 'rgba(33,43,54,.7)',
        textTransform: 'none',
      },
    },
  },
});

const messages = defineMessages({
  na: {
    id: 'survey.na',
    defaultMessage: 'NA',
  },
  terrible: {
    id: 'survey.terrible',
    defaultMessage: 'Terrible',
  },
  bad: {
    id: 'survey.bad',
    defaultMessage: 'Bad',
  },
  good: {
    id: 'survey.good',
    defaultMessage: 'Good',
  },
  excellent: {
    id: 'survey.excellent',
    defaultMessage: 'Excellent',
  },
});

type QuestionProps = {
  label: string;
  helperText: string;
  value?: string | null;
  disableNa?: boolean;
  onChange: (value: any) => void;
};

export default function EmojiQuestion({ label, helperText, value, disableNa, onChange }: QuestionProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Box paddingBottom={4}>
      <FormLabel component="legend">
        <Typography variant="h2" className={classes.label}>
          {label}
        </Typography>
      </FormLabel>
      <FormHelperText>
        <Typography gutterBottom className={classes.helper}>
          {helperText}
        </Typography>
      </FormHelperText>

      <ToggleButtonGroup
        exclusive
        size="large"
        value={value}
        onChange={(_e, value) => onChange(value)}
        className={classes.buttonGroup}
      >
        <ToggleButton value="-1" disableRipple disableFocusRipple disabled={disableNa}>
          {!disableNa && (
            <>
              <Box mb={1} py={2.5}>
                <img src={`/images/emoji/na${value === '-1' ? '-on' : ''}.svg`} alt={formatMessage(messages.na)} />
              </Box>
              <FormattedMessage {...messages.na} />
            </>
          )}
        </ToggleButton>
        <ToggleButton value="2.5" disableRipple disableFocusRipple>
          <Box mb={1}>
            <img
              src={`/images/emoji/terrible${value === '2.5' ? '-on' : ''}.svg`}
              alt={formatMessage(messages.terrible)}
            />
          </Box>
          <FormattedMessage {...messages.terrible} />
        </ToggleButton>
        <ToggleButton value="5.0" disableRipple disableFocusRipple>
          <Box mb={1}>
            <img src={`/images/emoji/bad${value === '5.0' ? '-on' : ''}.svg`} alt={formatMessage(messages.bad)} />
          </Box>
          <FormattedMessage {...messages.bad} />
        </ToggleButton>
        <ToggleButton value="7.5" disableRipple disableFocusRipple>
          <Box mb={1}>
            <img src={`/images/emoji/good${value === '7.5' ? '-on' : ''}.svg`} alt={formatMessage(messages.good)} />
          </Box>
          <FormattedMessage {...messages.good} />
        </ToggleButton>
        <ToggleButton value="10.0" disableRipple disableFocusRipple>
          <Box mb={1}>
            <img
              src={`/images/emoji/excellent${value === '10.0' ? '-on' : ''}.svg`}
              alt={formatMessage(messages.excellent)}
            />
          </Box>
          <FormattedMessage {...messages.excellent} />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
