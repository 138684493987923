import { FormEvent } from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
} from '@material-ui/core';

import UnsubscribeService from 'services/unsubscribe';

const queryString = require('query-string');

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
  },
  logoContainer: {
    paddingTop: 150,
    marginBottom: 65,
    '& img': { width: 200 },
  },
  radios: {
    textAlign: 'left',
    marginTop: '1em',
  },
}));

export default function UnsubscribeScreen() {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const params = queryString.parse(useLocation().search);
  const shipmentId = params.s;
  const emailType = params.o;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [unsubscriptionId, setUnsubscriptionId] = useState<string>();
  const [unsubscribedEmail, setUnsubscribedEmail] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [comment, setComment] = useState<string>();
  const [isDone, setIsDone] = useState(false);
  const [message, setMessage] = useState<string>();

  const reasons = [
    formatMessage({ id: 'unsubscribe.reasons.view', defaultMessage: 'I could not see the emails properly' }),
    formatMessage({ id: 'unsubscribe.reasons.many', defaultMessage: 'Emails were sent too frequently' }),
    formatMessage({ id: 'unsubscribe.reasons.uninterested', defaultMessage: 'I am not interested in Easyship' }),
    formatMessage({
      id: 'unsubscribe.reasons.unasked',
      defaultMessage: 'I never asked to receive any emails from Easyship',
    }),
  ];

  useEffect(() => {
    if (!shipmentId || !emailType) {
      setIsError(true);
      return;
    }

    UnsubscribeService.init(shipmentId, emailType)
      .then(({ id, status, email, error }) => {
        switch (status) {
          case 'already_done':
            setMessage(error as string);
            return;

          case 'not_found':
          case 'error':
            setIsError(true);
            return;
        }

        setIsLoading(false);
        setUnsubscriptionId(id);
        setUnsubscribedEmail(email);
      })
      .catch(() => setIsError(true));
  }, [shipmentId, emailType]);

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!unsubscriptionId) {
      setIsError(true);
      return;
    }

    UnsubscribeService.submit(unsubscriptionId, reason, comment)
      .then(() => {
        setIsDone(true);
      })
      .catch(() => {
        setIsError(true);
      });
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <div className={classes.logoContainer}>
        <img
          src="/images/track-my-shipment-logo.svg"
          alt={formatMessage({ id: 'home.img-alt', defaultMessage: 'Track My Shipment' })}
        />
      </div>
      {!isLoading && !isError && !message && !isDone && (
        <>
          <Typography variant="h1" gutterBottom>
            <FormattedMessage id="unsubscribe.sad" defaultMessage="We're sad to see you go!"></FormattedMessage>
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage
              id="unsubscribe.success"
              defaultMessage="The address <blue>{unsubscribedEmail}</blue> has been successfully unsubscribed and will no longer receive emails from Easyship."
              values={{
                unsubscribedEmail,
                blue: (text: string) => <span style={{ color: 'rgb(31, 154, 239)' }}>{text}</span>,
              }}
            ></FormattedMessage>
          </Typography>

          <Box mt={4}>
            <Typography variant="h2" gutterBottom>
              <FormattedMessage
                id="unsubscribe.help"
                defaultMessage="Help us to improve our services"
              ></FormattedMessage>
            </Typography>
            <form onSubmit={submit}>
              <Grid container direction="column">
                <FormControl component="fieldset" className={classes.radios}>
                  <FormLabel component="legend">
                    <FormattedMessage
                      id="unsubscribe.why"
                      defaultMessage="Why do you wish to unsubscribe today?"
                    ></FormattedMessage>
                  </FormLabel>
                  <RadioGroup value={reason} onChange={(e) => setReason(e.target.value)}>
                    {reasons.map((option) => (
                      <FormControlLabel value={option} control={<Radio />} label={option} />
                    ))}
                  </RadioGroup>
                </FormControl>

                <TextField
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  multiline
                  rows={4}
                  placeholder={formatMessage({
                    id: 'unsubscribe.placeholder',
                    defaultMessage: 'Additional comments',
                  })}
                  variant="outlined"
                />
                <Box mt={2} mb={8}>
                  <Button type="submit" variant="contained" color="primary">
                    <FormattedMessage id="unsubscribe.submit" defaultMessage="Submit"></FormattedMessage>
                  </Button>
                </Box>
              </Grid>
            </form>
          </Box>
        </>
      )}

      {isError && (
        <Typography>
          <FormattedMessage
            id="unsubscribe.error"
            defaultMessage="Something went wrong. Please try another link."
          ></FormattedMessage>
        </Typography>
      )}
      {message && <Typography>{message}</Typography>}
      {isDone && (
        <Typography>
          <FormattedMessage
            id="unsubscribe.thanks"
            defaultMessage="Thank you for submitting your feedback!"
          ></FormattedMessage>
        </Typography>
      )}
    </Container>
  );
}
