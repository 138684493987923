import { UnsubscribeResponse, UnsubscribeOrigin } from 'services';

const API_BASE = process.env.REACT_APP_API_BASE;

async function init(shipmentId: string, emailType: UnsubscribeOrigin): Promise<UnsubscribeResponse> {
  const res = await fetch(`${API_BASE}/v1/unsubscribed_emails`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify({ unsubscribed_email: { email_id: shipmentId, origin: emailType } }),
  });

  if (!res.json) return { status: 'error' };

  return res.json().then((res) => {
    if (Array.isArray(res.email)) {
      if (/already/.test(res.email[0])) return { status: 'already_done', error: res.email[0] };
      if (/blank/.test(res.email[0])) return { status: 'not_found', error: res.email[0] };
    }

    return res;
  });
}

async function submit(unsubscriptionId: string, reason?: string, comment?: string) {
  const res = await fetch(`${API_BASE}/v1/unsubscribed_emails/${unsubscriptionId}`, {
    method: 'PATCH',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify({ unsubscribed_email: { id: unsubscriptionId, reason, comment } }),
  });

  return res.json ? res.json() : { status: 'error' };
}

const UnsubscribeService = { init, submit };
export default UnsubscribeService;
