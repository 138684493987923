import { useIntl } from 'react-intl';
import Lottie from 'lottie-react';
import prepIcon from './animations/0-prep.json';
import inTransitIcon from './animations/2-in-transit.json';
import outForDeliveryIcon from './animations/3-out-for-delivery.json';

import { ShipmentStatus } from 'services/index.d';

type StatusIconProps = React.HTMLAttributes<string> & {
  status: ShipmentStatus;
};

export default function StatusIcon(props: StatusIconProps) {
  const intl = useIntl();
  const animatedIcon = getAnimatedIcon();

  function getAnimatedIcon() {
    switch (props.status) {
      case ShipmentStatus.Prep:
        return prepIcon;
      case ShipmentStatus.InTransit:
        return inTransitIcon;
      case ShipmentStatus.OutForDelivery:
        return outForDeliveryIcon;
    }
  }

  return (
    <>
      {animatedIcon ? (
        <Lottie animationData={animatedIcon} />
      ) : (
        <img
          src={`/images/${props.status}.svg`}
          alt={intl.formatMessage({
            id: `tracking-status.icon`,
            defaultMessage: 'Tracking Status Icon',
          })}
        />
      )}
    </>
  );
}
