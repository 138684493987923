import TrackingResource from './resource';
import { Shipment } from '../index';

const REDIRECT_COMPANIES = ['CUS199515', 'CUS154114', 'CUS277455', 'CUS481125']; // [SendAFriend, Better Body EL, Proozy, Battle Beaver Customs]

async function find(keyword: string, recaptchaToken: string): Promise<Shipment | null> {
  return TrackingResource.find(encodeURIComponent(keyword), recaptchaToken).then((shipment) => {
    if (REDIRECT_COMPANIES.includes(shipment.easyship_company_id) && shipment.courier_tracking_url) {
      shipment.redirect_url = shipment.courier_tracking_url;
    }

    if (shipment.redirect_url) {
      window.location.replace(shipment.redirect_url);
    }

    return shipment;
  });
}

async function findPreview(companyId: string, recaptchaToken: string): Promise<Shipment> {
  return TrackingResource.findPreview(companyId, recaptchaToken);
}

async function shopify(action: 'track' | 'label', id: string, shop: string): Promise<Shipment> {
  const controller = action === 'label' ? 'label_info' : action;
  return TrackingResource.shopify(controller, id, shop);
}

const TrackingService = { find, findPreview, shopify };
export default TrackingService;
