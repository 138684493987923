import { useState, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CourierSurvey } from 'services';
import EmojiQuestion from './EmojiQuestion';

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    width: '100%',
  },
  textArea: {
    '& label': {
      fontSize: 16,
      lineHeight: '24px',
      color: 'rgba(33,43,54,.7)',
      fontWeight: 400,
      marginTop: -10,
    },
  },
  label: {
    fontSize: 16,
    lineHeight: '24px',
    color: 'rgba(33,43,54,.7)',
    fontWeight: 400,
  },
  helper: {
    color: '#919eab',
  },
}));

type EmojiQuestionaireProps = {
  userType: 'sender' | 'receiver';
  survey: CourierSurvey;
  sendReceiverSurvey: boolean;
  onSubmit: (survey: CourierSurvey, sendReceiverSurvey: boolean) => void;
};

export default function EmojiQuestionaire({ userType, survey, sendReceiverSurvey, onSubmit }: EmojiQuestionaireProps) {
  const classes = useStyle();
  const { formatMessage } = useIntl();
  const [form, setForm] = useState(survey);
  const [receiverSurveyPermission, setReceiverSurveyPermission] = useState(sendReceiverSurvey);

  const handleChange = (key: string, value: string) => {
    setForm({ ...form, [key]: value });
  };

  return (
    <>
      {userType === 'sender' ? (
        <FormControl component="fieldset" className={classes.root}>
          <EmojiQuestion
            label={formatMessage({ id: 'survey.overall-label', defaultMessage: 'Overall experience' })}
            helperText={formatMessage({
              id: 'survey.overall-helper-sender',
              defaultMessage: 'Were you satisfied with the service provided by the courier?',
            })}
            disableNa
            value={form.overall_experience_score}
            onChange={(value) => handleChange('overall_experience_score', value)}
          />
          <EmojiQuestion
            label={formatMessage({ id: 'survey.dropoff-label', defaultMessage: 'Drop-off experience' })}
            helperText={formatMessage({
              id: 'survey.dropoff-helper',
              defaultMessage:
                'Was the drop-off conveniently located, efficient in processing your shipment, and seamless overall?',
            })}
            value={form.dropoff_score}
            onChange={(value) => handleChange('dropoff_score', value)}
          />
          <EmojiQuestion
            label={formatMessage({ id: 'survey.pickup-label', defaultMessage: 'Pickup experience' })}
            helperText={formatMessage({
              id: 'survey.pickup-helper',
              defaultMessage: 'Did the courier come on time and were they professional?',
            })}
            value={form.pickup_score}
            onChange={(value) => handleChange('pickup_score', value)}
          />
          <EmojiQuestion
            label={formatMessage({ id: 'survey.service-label', defaultMessage: 'Customer service quality' })}
            helperText={formatMessage({
              id: 'survey.service-helper',
              defaultMessage: 'How satisfied were you with customer service?',
            })}
            value={form.customer_service_score}
            onChange={(value) => handleChange('customer_service_score', value)}
          />
        </FormControl>
      ) : (
        <FormControl component="fieldset" className={classes.root}>
          <EmojiQuestion
            label={formatMessage({ id: 'survey.speed-label', defaultMessage: 'Speed' })}
            helperText={formatMessage({
              id: 'survey.speed-helper',
              defaultMessage: 'Was delivery faster or slower than expected?',
            })}
            value={form.delivery_time_score}
            onChange={(value) => handleChange('delivery_time_score', value)}
          />
          <EmojiQuestion
            label={formatMessage({ id: 'survey.condition-label', defaultMessage: 'Condition' })}
            helperText={formatMessage({
              id: 'survey.condition-helper',
              defaultMessage: 'On arrival, how was the condition of the package?',
            })}
            value={form.handling_quality_score}
            onChange={(value) => handleChange('handling_quality_score', value)}
          />
          <EmojiQuestion
            label={formatMessage({ id: 'survey.overall-label', defaultMessage: 'Overall experience' })}
            helperText={formatMessage({
              id: 'survey.overall-helper-receiver',
              defaultMessage: 'Please rate the overall shipping experience',
            })}
            value={form.overall_experience_score}
            onChange={(value) => handleChange('overall_experience_score', value)}
          />
        </FormControl>
      )}
      <FormControl component="fieldset" className={classes.root}>
        <TextField
          label={formatMessage({
            id: 'survey.describe-label',
            defaultMessage: 'What was it like to ship with this courier',
          })}
          placeholder={formatMessage({ id: 'survey.describe-placeholder', defaultMessage: 'Describe your experience' })}
          multiline
          fullWidth
          rows={4}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('comments', e.target.value)}
          className={classes.textArea}
        />

        {userType === 'sender' && (
          <Box marginTop={4}>
            <FormControl component="fieldset" className={classes.root}>
              <FormLabel component="legend" className={classes.label}>
                <FormattedMessage
                  id="survey.permission-label"
                  defaultMessage="Can we send a survey to your recipients about their courier experience?"
                />
              </FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={receiverSurveyPermission}
                    onChange={(_e, value) => setReceiverSurveyPermission(value)}
                  />
                }
                label={
                  receiverSurveyPermission
                    ? formatMessage({ id: 'global.yes', defaultMessage: 'Yes' })
                    : formatMessage({ id: 'global.no', defaultMessage: 'No' })
                }
              />
              <FormHelperText className={classes.helper}>
                <FormattedMessage
                  id="survey.permission-helper"
                  defaultMessage="This feedback helps sellers like you choose the right courier for their business. We promise not to send your recipients any other communications outside of the survey"
                />
              </FormHelperText>
            </FormControl>
          </Box>
        )}

        <Box paddingTop={4} paddingBottom={8} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit(form, receiverSurveyPermission)}
            style={{ width: 150 }}
          >
            <FormattedMessage id="survey.submit" defaultMessage="Submit" />
          </Button>
        </Box>
      </FormControl>
    </>
  );
}
