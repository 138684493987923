import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px 0 45px',
    maxHeight: 150,
    overflow: 'hidden',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      padding: '15px 15px 25px',
      maxHeight: 180,
    },
  },
  reducePadding: {
    padding: '20px 15px 0 !important',
  },
  checkpointsContainer: {
    position: 'relative',
    maxHeight: 400,
    transition: 'max-height 2s ease-out',
  },
  checkpoint: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  expanded: {
    height: 'calc(100% + 50px)',
    maxHeight: 2500,
  },
  fade: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 80,
    background: 'linear-gradient(0deg, #fff, hsla(0,0%,100%,0))',
    zIndex: 2,
    pointerEvents: 'none',
  },
  expandTrackingContainer: {
    width: '100%',
    height: 26,
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 3,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandTracking: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',

    '& svg': {
      fontSize: 30,
    },
  },
  checkpointTime: {
    width: 150,
  },
}));
