import { makeStyles } from '@material-ui/core/styles';

import COLORS from '../config/colors';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    margin: '0 auto',
    width: 25,
    height: 25,
    backgroundColor: COLORS.greenDark,
    borderRadius: '100%',
    animation: '$scaleout 1.0s infinite ease-in-out',
  },
  centered: {
    marginTop: 'calc(50vh - 40px)',
    marginLeft: 'calc(50vw - 40px)',
  },
  bigger: {
    width: 40,
    height: 40,
  },
  biggest: {
    width: 80,
    height: 80,
  },
  '@keyframes scaleout': {
    '0%': {
      transform: 'scale(0.0)',
    },
    '100%': {
      transform: 'scale(1.0)',
      opacity: 0,
    },
  },
}));

interface ProgressIndicatorProps {
  size?: number;
  centered?: boolean;
}

export default function ProgressIndicator(props: ProgressIndicatorProps) {
  const classes = useStyles();

  function getSizeClass(size: number | undefined) {
    switch (size) {
      case 2:
        return classes.bigger;

      case 3:
        return classes.biggest;

      default:
        return '';
    }
  }

  return (
    <div className={`${classes.root} ${props.centered ? classes.centered : ''} ${getSizeClass(props.size)}`}></div>
  );
}
